import Client from './HttpClient';

const rule = (merchantId, branchId) => {
  return Client.get(`/eagle-api/reservations/setting-info`, {
    merchantId,
    branchId,
  });
};

const depositRules = (merchantId, branchId, type) => {
  return Client.get(`/eagle-api/reservations/deposit-setting`, {
    merchantId,
    branchId,
    type,
  });
};

const avaliableTime = (merchantId, branchId, numberOfPeople, bookingDate) => {
  return Client.get(`/eagle-api/reservations/${merchantId}/reserve-time`, {
    branchId,
    numberOfPeople,
    bookingDate,
    source: 1,
  });
};

const validReserveTime = (
  merchantId,
  branchId,
  numberOfPeople,
  bookingDate
) => {
  return Client.get(
    `/eagle-api/reservations/${merchantId}/valid-reserve-time`,
    {
      branchId,
      numberOfPeople,
      bookingDate,
      source: 1,
    }
  );
};

const place = (data) => {
  return Client.post(`/eagle-api/reservations/place`, data);
};

const cancel = (reservationId, merchantId, branchId) => {
  return Client.post(`/eagle-api/reservations/${reservationId}/cancel-apply`, {
    merchantId,
    branchId,
  });
};

const list = (merchantId, branchId, customerId, type, page, pageSize = 20) => {
  return Client.get(`/eagle-api/reservations/list`, {
    merchantId,
    branchId,
    customerId,
    type,
    pageSize,
    page,
  });
};

const find = (reservationId) => {
  return Client.get(`/eagle-api/reservations/order/detail`, {
    reservationId,
  });
};

const refund = (reservationId, merchantId, branchId) => {
  return Client.get(`/eagle-api/reservations/refund-deposit`, {
    reservationId,
    merchantId,
    branchId,
  });
};

const stores = (merchantId, lng, lat) => {
  return Client.get(`/eagle-api/reservations/branches`, {
    merchantId,
    lng,
    lat,
  });
};

const tags = (merchantId, branchId) => {
  return Client.get(`/eagle-api/reservations/tags`, {
    merchantId,
    branchId,
  });
};

const findByCode = (code) => {
  return Client.get(`/eagle-api/reservations/order/item/${code}`);
};

const cancelByCode = (code) => {
  return Client.post(`/eagle-api/reservations/order/cancel/${code}`);
};

const refundByCode = (code) => {
  return Client.post(`/eagle-api/reservations/order/refund-deposit/${code}`);
};

const noteTags = (merchantId, branchId) => {
  return Client.get(`/eagle-api/reservations/noteTags`, {
    merchantId,
    branchId,
  });
};

const specialCommMsg = (merchantId, branchId) => {
  return Client.get(`/eagle-api/reservations/reservationAgreements`, {
    merchantId,
    branchId,
  });
};

export default {
  rule,
  depositRules,
  avaliableTime,
  validReserveTime,
  place,
  cancel,
  list,
  find,
  refund,
  stores,
  tags,
  findByCode,
  cancelByCode,
  refundByCode,
  noteTags,
  specialCommMsg,
};
